<template>
  <div>
    <v-card>
      <v-tabs>
        <v-tab key="'individual-lessons'">Индивидуальные уроки</v-tab>
        <v-tab key="group-lessons">Групповые уроки</v-tab>
        <v-tab key="courses">Курсовые уроки</v-tab>
        <v-tab key="courses">Курсы</v-tab>
        <v-tab-item class="pt-3">
          <LessonsTable :dates="dates" lesson-type="individual-lessons" />
        </v-tab-item>
        <v-tab-item class="pt-3">
          <LessonsTable :dates="dates" lesson-type="group-lessons" />
        </v-tab-item>
        <v-tab-item class="pt-3">
          <LessonsTable :dates="dates" lesson-type="course-lessons" />
        </v-tab-item>
        <v-tab-item class="pt-3">
          <CoursesTable :dates="dates" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import LessonsTable from "@/components/Lessons/LessonsTable.vue";
import CoursesTable from "@/components/Lessons/CoursesTable.vue";

export default {
  props: {
    dates: {
      type: Array,
      required: true,
    },
  },
  components: {
    CoursesTable,
    LessonsTable,
  },
  data() {},
};
</script>

<style scoped></style>
