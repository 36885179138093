<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="coursesData"
      :server-items-length="totalItems"
      :loading="isDataLoading"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
      :items-per-page="optionsTable.itemsPerPage"
      :options.sync="optionsTable"
    >
      <template v-slot:item.status="{ item }">
        <v-chip :color="getBadgeColorByLessonStatus(item.status)" small>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.start_recruitment_timestamp="{ item }">
        {{ formatDate(item.start_recruitment_timestamp) }}
      </template>
      <template v-slot:item.end_recruitment_timestamp="{ item }">
        {{ formatDate(item.end_recruitment_timestamp) }}
      </template>
      <template v-slot:item.lessons_count="{ item }">
        {{ item.course_lessons.length }}
      </template>
      <template v-slot:item.course_details="{ item }">
        <v-btn
          text
          color="pink"
          class="ms-5"
          @click="showCourseDetails(item.id)"
          >подробнее</v-btn
        >
      </template>
    </v-data-table>

    <v-dialog
      v-if="isShowCourseDetailsModal && courseDetails"
      v-model="isShowCourseDetailsModal"
      max-width="1400px"
    >
      <v-card>
        <v-card-title class="text-h5">Уроки курса</v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Статус</th>
                <th class="text-left">id</th>
                <th class="text-left">Тема урока</th>
                <th class="text-left">Дата начала урока</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="lesson of courseDetails.course_lessons"
                :key="lesson.id"
              >
                <td>
                  <v-chip
                    :color="getBadgeColorByLessonStatus(lesson.status)"
                    small
                  >
                    {{ lesson.status }}
                  </v-chip>
                </td>
                <td>
                  {{ lesson.id }}
                </td>
                <td>
                  {{ lesson.title }}
                </td>
                <td>
                  {{ formatDate(lesson.timestamp) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/../api";
import moment from "moment";

export default {
  props: {
    dates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDataLoading: false,
      coursesData: [],
      totalItems: 0,
      optionsTable: {
        page: 1,
        itemsPerPage: 10,
      },

      courseDetails: null,
      isShowCourseDetailsModal: false,

      headers: [
        { text: "Статус", value: "status", sortable: false },
        {
          text: "id курса",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Предмет", value: "subject_name", sortable: false },
        {
          text: "Дата старта курса",
          align: "start",
          value: "start_recruitment_timestamp",
          sortable: false,
        },
        {
          text: "Дата окончания курса",
          align: "start",
          value: "end_recruitment_timestamp",
          sortable: false,
        },
        {
          text: "Длительность 1 урока (мин)",
          value: "lesson_duration",
          sortable: false,
        },
        {
          text: "Кол-во уроков в курсе",
          value: "lessons_count",
          sortable: false,
        },
        {
          text: "Детали курса",
          value: "course_details",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    dates: {
      handler: async function (val, oldVal) {
        if (val.toString() !== oldVal.toString()) {
          await this.loadData();
        }
      },
      immediate: true,
      deep: true,
    },
    optionsTable: async function (val, oldVal) {
      if (val !== oldVal) {
        await this.loadData();
      }
    },
  },
  methods: {
    async loadData() {
      try {
        this.isDataLoading = true;
        const { page, itemsPerPage } = this.optionsTable;
        const props = {
          page,
          itemsPerPage,
          date_from: this.dates[0],
          date_to: this.dates[1] || this.dates[0],
        };
        const {
          data: { items, total },
        } = await api.lessons.coursesStatistics(props);
        this.coursesData = items;
        this.totalItems = total;
      } catch (err) {
        console.error(err);
      } finally {
        this.isDataLoading = false;
      }
    },
    getBadgeColorByLessonStatus(status) {
      const colors = {
        new: "#dedede",
        canceled: "#d4c1ff",
        completed: "#aae57b",
        started: "#a4f0ff",
        failed: "#ffa5a5",
        failed_by_tutor: "#ffb580",
        failed_by_student: "#fde561",
        ending_process: "#dedede",
      };
      return colors[status] || "#dedede";
    },
    async showCourseDetails(courseId) {
      const { data } = await api.lessons.courseStatistics(courseId);
      this.courseDetails = data;
      this.isShowCourseDetailsModal = true;
    },
    formatDate(date) {
      return date ? moment(date).format("YYYY-MM-DD HH:mm") : "---";
    },
  },
};
</script>
